import Heading from '../../atoms/Heading/Heading.tsx';

function NotFound() {
  return (
    <div>
      <Heading>The page you are looking for cannot be found, sorry!</Heading>
    </div>
  );
}

export default NotFound;
